export default {
    common: {
        appName:"pg工具",
        appKeyword: "pg工具,实用工具,程序员工具,在线小工具,在线文本工具,在线图片工具,在线计算工具,在线网络工具,在线生活工具,在线小游戏",
        setting:'设置',
        menu:'菜单',
        notFoundMsg: '对不起，您访问的页面找不到了',
        jumpAfterSeconds: '秒后自动跳转...',

        button: {
            convert: "转换",
            format:'格式化',
            compress: '压缩化',
            empty: "清空",
            copy: "复制",
            paste: "粘贴",
            upload: "上传",
            download: "下载",
            fullscreen: '全屏',
            test: "测试",
            generate: "生成",
            compare: "对比",
            edit: "编辑",
            encode: "编码",
            decode: "解码",
            encrypt: "加密",
            decrypt: "解密",
            operate: "操作",
            statistics: "统计",
            locate: "定位",
            query: "查询",
            translate: '翻译',
            search: '搜索',
            open: '打开',
            exit: '退出',
            escape: '转义',
            unescape: '反转义',
        },
        message: {
            searchForTool: '请搜索工具',
            textContent: "文本内容",
            base64Content: "base64内容",
            copySuccess: '复制成功',
            copyFail: '复制失败',
            copyFailEmptyText: '复制失败，文本不能为空',
            pasteSuccess: '粘贴成功',
            pasteFail: '粘贴失败',
            convertSuccess: '转换成功',
            convertFail: '转换失败',
            querySuccess: '查询成功',
            queryFail: '查询失败',
            encryptSuccess: '加密成功',
            encryptFail: '加密失败',
            decryptSuccess: '解密成功',
            decryptFail: '解密失败',
            encodeSuccess: '编码成功',
            encodeFail: '编码失败',
            decodeSuccess: '解码成功',
            decodeFail: '解码失败',
            pleaseConvert: '请先转换',
            uploadSuccess: '上传成功',
            uploadFail: '上传失败',
            formatSuccess: "格式化成功",
            formatFail: "格式化失败",
            locateSuccess: '定位成功',
            locateFail: '定位失败',
            translateSuccess: '翻译成功',
            translateFail: '翻译失败',
            searchSuccess: '搜索成功',
            searchFail: '搜索失败',
            openSuccess: '打开成功',
            openFail: '打开失败',
            requestSuccess: '请求成功',
            requestFail: '请求失败',
            escapeSuccess: '转义成功',
            escapeFail: '转义失败',
            unescapeSuccess: '反转义成功',
            unescapeFail: '反转义失败',
            downloadSuccess: '下载成功',
            downloadFail: '下载失败',
            uploadOneFile: '请上传一个文件',
            uploadFileSizeExceedLimit: '上传文件大小超过限制',
            input: '请输入',
            inputText: '请输入文本',
            loading: '加载中',
            select: '请选择',
            chooseType: '请选择类型',
            wrongTextFormat: '文本格式错误',
            inputError: '输入有误',
            beforeConvert: '转换前',
            afterConvert: '转换后',
            testFail: '测试失败',
            match: '结果匹配',
            notMatch: '结果不匹配',
            regexpType: "正则表达式类型",
            choose: "请选择",
            regexp: "正则表达式",
            testContent: "测试内容",
            randomText: "随机文本",
            generateFail: "生成失败",
            generateSuccess: "生成成功",
            wrongTextLength: "文本长度错误",
            noImage: "无图片",
            nonsupportType: '不支持的类型',
            textCompare: "文本对比",
            leftText: "左文本",
            rightText: "右文本",
            theme: "主题",
            language: "语言",
            textEditor: "文本编辑器",
            statistics: "统计",
            pleaseInput: "请输入: ",
            key: "秘钥",
            priKey: "私钥",
            pubKey: "公钥",
            iv: "偏移量",
            pleaseDownload: "请下载文件",
            location: '定位信息',
            weather: '天气信息',
            yourPosition: '★',
            translate: '翻译',
            keyword: '关键词',
            notFound: '未找到数据',
            picture: '图片',
            docReader: '文档阅读器',
            time: '时间',
            requestPath: '请求地址',
            value: '值',
            unitType: '单位类型',
            unit: '单位',
            suchAs: '例如',
            colorFormatError: '颜色格式有误',
            colorRgb:  'rgb颜色',
            colorHex: 'hex颜色',
            colorAlpha: '透明度',
            switch: '切换'
        },
        tip: {
            uploadImgTip: '只能选择一个文件，图片格式'
        },
        colorTableType: {
            all: '颜色大全',
            safe: '安全颜色'
        },
        caseType: {
            uppercase: '大写',
            lowercase: '小写'
        },
        regexType: {
            default: "正则表达式类型",
            mobile: "手机号码",
            idCard: "身份证号码",
            email: "电子邮箱",
            chinese: "中文",
            url: "网址",
            httpUrl: "http网址",
            ip4: "ip4地址",
            ip6: "ip6地址",
            money: "货币",
            postCode: "邮编",
            mac: "mac",
            hex: "十六进制数字",
            time: "时间",
            creditCode: "统一社会信用代码",
            chCarCode: "车牌号码",
            general: "英文字母 、数字和下划线",
            chGeneral: "英文字母 、数字和下划线和中文",
            number: "数字",
            word: "字母",
        },
        randomType: {
            digit: "数字",
            smallChar: "小写字母",
            bigChar: "大写字母",
            special: "特殊字符",
            timestamp: "时间戳",
            datetimeString1: "时间字符串1",
            datetimeString2: "时间字符串2",
            uuid: "uuid",
            snowflakeId: "雪花id",
        },
        convertType: {
            jsonToXml: 'json转xml',
            xmlToJson: 'xml转json',
            lowerToUpper: '小写字母转大写字母',
            upperToLower: '大写字母转小写字母',
            jianToFan: '简体转繁体',
            fanToJian: '繁体转简体',
            cnToTw: '简体转台湾繁体',
            twToCn: '台湾繁体转简体',
            cnToTwp: '简体转台湾繁体(单词)',
            twpToCn: '台湾繁体(单词)转简体',
            cnToHk: '简体转香港繁体',
            hkToCn: '香港繁体转简体',
            cnToJp: '简体转日本字体',
            jpToCn: '日本新字体转简体',
            numToCh: '数字转中文',
            chToPinyin: '中文转拼音',
        },
        codeDiffFormatType: {
            side: '左右',
            line: '按行'
        },
        showNoChangeType: {
            show: '展示相同',
            hide: '隐藏相同'
        },
        formatCompressType: {
            format: "格式化",
            compress: '压缩化'
        },
        picFilterType: {
            default: "无滤镜",
            relief1: "浮雕1",
            relief2: "浮雕2",
            sculpture: "雕刻",
            mirror: "镜像翻转",
            upsideDown: "上下翻转",
            negative:"反色",
            oldPhoto:"老照片",
            particle1:"粒子1",
            particle2:"粒子2",
            mosaic:"马赛克",
            mote:"微粒",
            blackAndWhite:"黑白",
            gray1:"灰度1",
            gray2:"灰度2",
            gauss1:"高斯模糊1",
            gauss2:"高斯模糊2",
            gauss3:"高斯模糊3",
            light:"高亮",
            dark:"黑暗",
            opacity:"透明",
            rChannel:"红调色",
            gChannel:"绿调色",
            bChannel:"蓝调色",
            rgChannel:"黄调色",
            rbChannel:"紫调色",
            gbChannel:"青调色",
            rgbChannel:"自由调色",
            rMask:"红蒙版",
            gMask:"绿蒙版",
            bMask:"蓝蒙版",
            rgMask:"黄蒙版",
            rbMask:"紫蒙版",
            gbMask:"青蒙版",
        },
        picClipType: {
            startClip: "开始裁剪",
            stopClip: "停止裁剪",
            clearClip: "清除裁剪",
            bigger: "放大",
            smaller: "缩小",
            rotateLeft: "向左旋转",
            rotateRight: "向右旋转",
            revert: "复位",
        },
        textStatistics: {
            total: '字符总数',
            number: '数字',
            letter: '字母',
            upper: '大写字母',
            lower: '小写字母',
            ch: '中文',
            special: '特殊字符',
            whitespace: '空白符',
            space: '空格符',
            tab: 'tab符',
            lf: '回车换行符',
            size: '文本大小',
            line: '文本行数',
        },
        game: {
            start: "开始游戏",
            finish: "结束游戏",
            lose: "你输了",
            over: "游戏结束",
            pause: '暂停',
            resume: '继续',
            pauseGame: '游戏暂停',
            resumeGame: '游戏继续',
            score: "分数",
            time: "时间",
            costTime: "耗时"
        },
        time: {
            second: '秒',
            minute: '分钟',
            hour: '小时',
            day: '天'
        },
        date: {
            Y: '年',
            M: '月',
            D: '日',
            h: '时',
            m: '分',
            s: '秒',
        },
        searchEngineType: {
            common: '综合',
            picture: '图片',
            video: '视频',
            music: '音乐',
            news: '新闻',
            blogs: '博客',
            software: '软件',
            shop: '购物',
            knowledge: '知识',
            encyclopedia: '百科',
            document: '文档',
            map: '地图',
            doctor: '问医',
        },
        api: {
            baidu: '百度',
            tencent: '腾讯',
            amap: '高德',

            weibo: '微博',
            weixin: '微信',
            zhihu: '知乎',

            iciba: '金山词霸',
            youdao: '有道',

            so: '360',
            sogou: '搜狗',
            bing: '必应',
            google: "谷歌",
            chinaso: "中国搜索",

            wenxinyiyan: '文心一言',
            xunfeixinghuo: '讯飞星火',

            kugouMusic: '酷狗音乐',
            kuwoMusic: '酷我音乐',
            wangyiyunMusic: '网易云音乐',
            tencentMusic: 'QQ音乐',
            qianqianMusic: '千千音乐',
            jiukuMusic: '九酷音乐',
            yitingMusic: '一听音乐',

            zjwaterTyphoon: '浙江水利台风',
            weatherTyphoon: '天气网台风',
            istrongcloudTyphoon: '四创台风',
            ceicEarthQuake: '中国地震台网',

            tencentNews: "腾讯新闻",
            toutiao: "今日头条",
            wangyiNews: "网易新闻",
            sinaNews: "新浪新闻",
            sohuNews: "搜狐新闻",
            baiduNews: "百度新闻",
            cctvNews: "央视新闻",
            chinaNews: "中国新闻",
            ifengNews: "凤凰网",
            huanqiuNews: "环球网",
            xinhuaNews: "新华网",

            bilibili: "哔哩哔哩",
            baiduVideo: "百度视频",
            sohuVideo: "搜狐视频",
            mgtv: "芒果tv",
            baofeng: "暴风影音",
            pptv: "pptv",
            hao123Video: "好123视频",
            youku: "优酷",
            aiqiyi: "爱奇艺",
            xigua: "西瓜视频",
            tudou: "土豆",
            iqiyi: "爱奇艺",
            tencentVideo: "腾讯视频",

            douyin: "抖音",
            kuaishou: "快手",
            haokan: "好看视频",

        },
        translate: {
            Word: '单词',
            Sentence: '短语'
        },
        location: {
            address: '地址',
            country: '国家',
            province: '省份',
            city: '城市',
            district: '地区',
            street: '街道',
            streetCode: '街道代号',
            town: '城镇',
            townCode: '城镇代码',
            addressCode: '地址代号',
            longitude: '经度',
            latitude: '纬度',
            nation: '国家',
            nationCode: '国家代号',
            cityPosition: '城市坐标（左下和右上）',
            current: '当前位置',
            coordinate: '经纬度'
        },
        weatherType: {
            weather: "天气",
            typhoon: "台风",
            earthquake: "地震",
        },
        weather: {
            todayWeather: '今日天气',
            text: '天气描述',
            temp: '温度',
            feelTemp: '体感湿度',
            rh: '相对湿度',
            windClass: '风力等级',
            windDesc: '风向描述',
            updateTime: '更新时间',
            week: '星期',
            textDay: '白天天气描述',
            textNight: '夜晚天气描述',
            tempHigh: '最高气温',
            tempLow: '最低气温',
            tempDay: '白天气温',
            tempNight: '夜晚气温',
            windClassDay: '白天风力',
            windDescDay: '白天风向',
            windClassNight: '夜晚风力',
            windDescNight: '夜晚风向',
        },
        timeFormat: {
            auto: '自动识别',
            timestamp: '时间戳',
            timeInterval: '时间间隔',
            ymdhms1: '年月日时分秒',
            ymdhms2: '年-月-日 时:分:秒',
            ymdhms3: '年/月/日 时:分:秒',
            ymdhmsS1: '年月日时分秒毫秒',
            ymdhmsS2: '年-月-日 时:分:秒:毫秒',
            ymdhmsS3: '年/月/日 时:分:秒:毫秒',
            ymd1: '年月日',
            ymd2: '年-月-日',
            ymd3: '年/月/日',
            pat: {
                ymdhms1: 'yyyyMMddHHmmss',
                ymdhms2: 'yyyy-MM-dd HH:mm:ss',
                ymdhms3: 'yyyy/MM/dd HH:mm:ss',
                ymdhmsS1: 'yyyyMMddHHmmssSSS',
                ymdhmsS2: 'yyyy-MM-dd HH:mm:ss:SSS',
                ymdhmsS3: 'yyyy/MM/dd HH:mm:ss:SSS',
                ymd1: 'yyyyMMdd',
                ymd2: 'yyyy-MM-dd',
                ymd3: 'yyyy/MM/dd',
            }
        },
        unitType: {
            length: {
                name: '长度',
                m: '米(m)',
                km: '千米(km)',
                dm: '分米(dm)',
                cm: '厘米(cm)',
                mm: '毫米(mm)',
                um: '微米(um)',
                nm: '纳米(nm)',
                pm: '皮米(pm)',
                ly: '光年(ly)',
                au: '天文单位(AU)',
                in: '英寸(in)',
                ft: '英尺(ft)',
                yd: '码(yd)',
                mi: '英里(mi)',
                nmi: '海里(nmi)',
                fm: '英寻(fm)',
                fur: '弗隆(fur)',
                mil: '密耳(mil)',
                cli: '里',
                czhang: '丈',
                cchi: '尺',
                ccun: '寸',
                cfen: '分',
                cli1: '厘',
                chao: '毫',
            },
            area: {
                name: '面积',
                m2: '平方米(m²)',
                km2: '平方千米(km²)',
                ha: '公顷(ha)',
                are: '公亩(are)',
                dm2: '平方分米(dm²)',
                cm2: '平方厘米(cm²)',
                mm2: '平方毫米(mm²)',
                acre: '英亩(acre)',
                sqmi: '平方英里(sq.mi)',
                sqyd: '平方码(sq.yd)',
                sqft: '平方英尺(sq.ft)',
                sqin: '平方英寸(sq.in)',
                sqrd: '平方竿(sq.rd)',
                cqing: '顷',
                cmu: '亩',
                cfen: '分',
                cchi: '平方尺',
                ccun: '平方寸',
            },
            volume: {
                name: '体积',
                m3: '立方米(m³)',
                km3: '立方千米(km³)',
                dm3: '立方分米(dm³)',
                cm3: '立方厘米(cm³)',
                mm3: '立方毫米(mm³)',
                l: '升(l)',
                dl: '分升(dl)',
                ml: '毫升(ml)',
                cl: '厘升(cl)',
                ul: '微升(ul)',
                hl: '公石(hl)',
                cuft: '立方英尺(cu ft)',
                cuin: '立方英寸(cu in)',
                cuyd: '立方码(cu yd)',
                min: '亩英尺(acre in)',
                ukgal: '英制加仑(uk gal)',
                usgal: '美制加仑(us gal)',
                ukoz: '英制液体盎司(oz)',
                usoz: '美制液体盎司(oz)',
            },
            weight: {
                name: '质量',
                kg: '千克(kg)',
                g: '克(g)',
                mg: '毫克(mg)',
                ug: '微克(μg)',
                t: '吨(t)',
                q: '公担(q)',
                lb: '磅(lb)',
                oz: '盎司(oz)',
                ct: '克拉(ct)',
                gr: '格令(gr)',
                lt: '长吨(lt)',
                st: '短吨(st)',
                ukhw: '英担',
                ushw: '美担',
                dr: '打兰(dr)',
                cdan: '担',
                cjin: '斤',
                cliang: '两',
                cqian: '钱',
                cfen: '分',
            },
            temperature: {
                name: '温度',
                C: '摄氏度(℃)',
                K: '开氏度(K)',
                F: '华氏度(℉)',
                R: '兰氏度(°R)',
                Re: '列氏度(°Re)',
            },
            pressure: {
                name: '压力',
                pa: '帕斯卡(Pa)',
                hpa: '百帕(hpa)',
                kpa: '千帕(kpa)',
                mpa: '兆帕(MPa)',
                atm: '标准大气压(atm)',
                mmHg: '毫米汞柱(mmHg)',
                inHg: '英寸汞柱(in Hg)',
                bar: '巴(bar)',
                mbar: '毫巴(mbar)',
                psf: '磅力/平方英尺(psf)',
                psi: '磅力/平方英寸(psi)',
                mmAq: '毫米水柱',
                kgfcm2: '公斤力/平方厘米(kgf/cm²)',
                kgfm2: '公斤力/平方米(kgf/㎡)',
            },
            power: {
                name: '功率',
                w: '瓦(W)',
                kw: '千瓦(kW)',
                hp: '英制马力(hp)',
                ps: '米制马力(ps)',
                kgms: '公斤·米/秒(kg·m/s)',
                kcals: '千卡/秒(kcal/s)',
                btus: '英热单位/秒(Btu/s)',
                ftlbs: '英尺·磅/秒(ft·lb/s)',
                js: '焦耳/秒(J/s)',
                nms: '牛顿·米/秒(N·m/s)',
            },
            energy: {
                name: '功/能/热',
                j: '焦耳(J)',
                kj: '千焦(kJ)',
                kgm: '公斤·米(kg·m)',
                psh: '米制马力·时(ps·h)',
                hph: '英制马力·时(hp·h)',
                kwh: '千瓦·时(kW·h)',
                cal: '卡(cal)',
                kcal: '千卡(kcal)',
                btu: '英热单位(btu)',
                ftlb: '英尺·磅(ft·lb)',
            },
            density: {
                name: '密度',
                kgm3: '千克/立方米(kg/m³)',
                kgcm3: '千克/立方厘米(kg/cm³)',
                kgdm3: '千克/立方分米(kg/dm³)',
                gcm3: '克/立方厘米(g/cm³)',
                gdm3: '克/立方分米(g/dm³)',
                gm3: '克/立方米(g/m³)',
            },
            force: {
                name: '力',
                n: '牛(N)',
                kn: '千牛(kN)',
                kgf: '千克力(kgf)',
                gf: '克力(gf)',
                tf: '公吨力(tf)',
                ip: '磅力(ip)',
                kip: '千磅力(kip)',
                dyn: '达因(dyn)',
            },
            time: {
                name: '时间',
                s: '秒(s)',
                yr: '年(yr)',
                week: '周(week)',
                d: '天(d)',
                h: '时(h)',
                min: '分(min)',
                ms: '毫秒(ms)',
                us: '微秒(μs)',
                ns: '纳秒(ns)',
            },
            speed: {
                name: '速度',
                ms: '米/秒(m/s)',
                kms: '千米/秒(km/s)',
                kmh: '千米/时(km/h)',
                c: '光速(c)',
                mach: '马赫(mach)',
                mileh: '英里/时(mile/h)',
                ins: '英寸/秒(in/s)',
            },
            dataSize: {
                name: '存储',
                b: '字节(b)',
                bit:'比特(bit)',
                kb: '千字节(KB)',
                mb: '兆字节(MB)',
                gb: '千兆字节(GB)',
                tb: '太字节(TB)',
                pb: '拍字节(PB)',
                eb: '艾字节(EB)',
            },
            angle: {
                name: '角度',
                d: '角度(°)',
                circle: '圆周(circle)',
                rg: '直角(right angle)',
                gon: '百分度(gon)',
                minute: '分(′min)',
                second: '秒(sec)',
                rad: '弧度(rad)',
                mrad: '毫弧度(mrad)',
            },
            rmb: {
                name: '人民币',
                yuan: '元',
                jiao: '角',
                fen: '分',
            },
        }
    },
    setting: {
        darkMode: '暗黑模式',
        enMode: "语言模式",
        leftRightPattern: "左右排版",
        topMenuPattern: "顶部菜单",
        secondMenuPattern: "二级菜单",
        themeColor: "主题颜色"
    },
    menu: {
        textTool: "文本工具",
        textEditorTool: "文本编辑器",
        docReaderTool: "文档阅读器",
        textFormatTool: "文本格式化工具",
        jsonEditorTool: "json编辑器",
        timeFormatTool: "时间格式化工具",
        textConversionTool: "文本转换工具",
        textRegexpTool: "正则表达式工具",
        textRandomTool: "随机数工具",
        textCompareTool: "文本对比工具",
        textEncodeTool: "文本编码工具",
        textEncryptTool: "文本加密工具",
        textStatisticsTool: "文本统计工具",
        textTranslateTool: "文本翻译工具",
        textEscapeTool: "文本转义工具",
        cronEditorTool: 'cron表达式工具',

        pictureTool: "图片工具",
        picBase64Tool: "图片base64转换工具",
        picFormatTool: "图片格式转换工具",
        picFilterTool: "图片滤镜工具",
        picClipTool: "图片裁剪工具",
        picSearchTool: "图片搜索工具",
        qrCodeTool: '二维码工具',
        colorTool: '颜色工具',

        calculatorTool: "计算工具",
        unitConverterTool: "单位换算工具",

        lifeTool: "生活工具",
        locationTool: "定位工具",
        weatherTool: "天气工具",

        websiteTool: "网址工具",
        searchEngineTool: '搜索引擎网站',
        aiChartTool: 'AI聊天网站',
        musicWebsiteTool: '音乐网站',
        newsWebsiteTool: '新闻网站',
        videoWebsiteTool: '视频网站',
        shortVideoWebSiteTool: '短视频网站',

        game: "小游戏",
        twoZeroFourEightGame: "2048",
        twoZeroFourEightGame1: "2048",
        tetrisGame: "俄罗斯方块",
    },
    menuDesc: {
        textTool: "文本工具",
        textEditorTool: "javascript/html/css/java/python/xml/json等数十种格式文本编辑器，基于ace",
        docReaderTool: "word、excel、pdf、ppt阅读器",
        textFormatTool: "json、xml等文本格式化工具",
        jsonEditorTool: "json编辑器",
        timeFormatTool: "时间戳、时间字符串等格式化工具",
        textConversionTool: "json、xml等文本转换工具",
        textRegexpTool: "正则表达式验证工具",
        textRandomTool: "随机数、随机文本生成工具",
        textCompareTool: "文本内容对比工具",
        textEncodeTool: "文本编码转换工具",
        textEncryptTool: "文本加解密转换工具",
        textStatisticsTool: "文本字符统计工具",
        textTranslateTool: "文本翻译工具",
        textEscapeTool: "文本转义工具",
        cronEditorTool: 'cron表达式生成/转换工具',

        pictureTool: "图片工具",
        picBase64Tool: "图片和base64文本转换工具",
        picFormatTool: "jpg、png、webp等图片格式转换工具",
        picFilterTool: "图片滤镜特效处理工具",
        picClipTool: "图片裁剪/放大/缩小/旋转工具",
        picSearchTool: "图片搜索工具",
        qrCodeTool: '二维码生成/解析工具',
        colorTool: '颜色工具',

        calculatorTool: "计算工具",
        unitConverterTool: "长度/面积/体积/质量/温度/时间/数据/速度等单位换算工具",

        lifeTool: "生活工具",
        locationTool: "获取ip/经纬度/省份城市的定位工具",
        weatherTool: "获取当前/未来天气工具",

        websiteTool: "网址工具",
        searchEngineTool: '搜索引擎网站',
        aiChartTool: 'AI聊天网站',
        musicWebsiteTool: '音乐网站',
        newsWebsiteTool: '新闻网站',
        videoWebsiteTool: '视频网站',
        shortVideoWebSiteTool: '短视频网站',

        game: "小游戏",
        twoZeroFourEightGame: "益智小游戏-2048",
        twoZeroFourEightGame1: "益智小游戏-2048",
        tetrisGame: "益智小游戏-俄罗斯方块",
    }
};
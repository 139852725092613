export const pgMenuList = [
    //文本工具
    {
        name: 'textTool',
        category: 'text',
        icon: 'iconfont icon-wenben',
        isShow: true,
        children: [
            {
                name: 'textEditorTool',
                icon: 'iconfont icon-bianji',
                isShow: true
            },
            {
                name: 'docReaderTool',
                icon: 'iconfont icon-Word',
                isShow: true
            },
            {
                name: 'textFormatTool',
                icon: 'iconfont icon-format_icon',
                isShow: true
            },
            {
                name: 'jsonEditorTool',
                icon: 'iconfont icon-json',
                isShow: true
            },
            {
                name: 'timeFormatTool',
                icon: 'iconfont icon-format_icon',
                isShow: true
            },
            {
                name: 'textConversionTool',
                icon: 'iconfont icon-case',
                isShow: true
            },
            {
                name: 'textRegexpTool',
                icon: 'iconfont icon-zhengzebiaodashi_1',
                isShow: true
            },
            {
                name: 'textRandomTool',
                icon: 'iconfont icon-suijishushengcheng',
                isShow: true
            },
            {
                name: 'textCompareTool',
                icon: 'iconfont icon-duibi',
                isShow: true
            },
            {
                name: 'textEncodeTool',
                icon: 'iconfont icon-codebianma',
                isShow: true
            },
            {
                name: 'textEncryptTool',
                icon: 'iconfont icon-jiami',
                isShow: true
            },
            {
                name: 'textStatisticsTool',
                icon: 'iconfont icon-shujutongji',
                isShow: true
            },
            {
                name: 'textTranslateTool',
                icon: 'iconfont icon-fanyi',
                isShow: true
            },
            {
                name: 'textEscapeTool',
                icon: 'iconfont icon-sync',
                isShow: true
            },
            {
                name: 'cronEditorTool',
                icon: 'iconfont icon-uniE',
                isShow: true
            }
        ]
    },
    //图片工具
    {
        name: 'pictureTool',
        category: 'picture',
        icon: 'iconfont icon-tupian',
        isShow: true,
        children: [
            {
                name: 'picBase64Tool',
                icon: 'iconfont icon-tuwenxiangqing',
                isShow: true
            },
            {
                name: 'picFormatTool',
                icon: 'iconfont icon-geshizhuanhuan',
                isShow: true
            },
            {
                name: 'picFilterTool',
                icon: 'iconfont icon-lvjing',
                isShow: true
            },
            {
                name: 'picClipTool',
                icon: 'iconfont icon-crop-full',
                isShow: true
            },
            {
                name: 'picSearchTool',
                icon: 'iconfont icon-xian',
                isShow: true
            },
            {
                name: 'qrCodeTool',
                icon: 'iconfont icon-erweima',
                isShow: true
            },
            {
                name: 'colorTool',
                icon: 'iconfont icon-yanse',
                isShow: true
            }
        ]
    },
    //计算工具
    {
        name: 'calculatorTool',
        category: 'calculator',
        icon: 'iconfont icon-jisuan',
        isShow: true,
        children: [
            {
                name: 'unitConverterTool',
                icon: 'iconfont icon-danweihuansuan-',
                isShow: true
            }
        ]
    },
    //生活工具
    {
        name: 'lifeTool',
        category: 'life',
        icon: 'iconfont icon-tubiaozhizuomobanyihuifu-',
        isShow: true,
        children: [
            {
                name: 'weatherTool',
                icon: 'iconfont icon-tianqi',
                isShow: true
            },
            {
                name: 'locationTool',
                icon: 'iconfont icon-dingwei',
                isShow: true
            }
        ]
    },
    //网址工具
    {
        name: 'websiteTool',
        category: 'website',
        icon: 'iconfont icon-zcpt-wangzhanguanli',
        isShow: true,
        children: [
            {
                name: 'searchEngineTool',
                icon: 'iconfont icon-wangzhan1',
                isShow: true
            },
            {
                name: 'aiChartTool',
                icon: 'iconfont icon-wuguan',
                isShow: true
            },
            {
                name: 'newsWebsiteTool',
                icon: 'iconfont icon-xinwen',
                isShow: true
            },
            {
                name: 'musicWebsiteTool',
                icon: 'iconfont icon-yinle',
                isShow: true
            },
            {
                name: 'videoWebsiteTool',
                icon: 'iconfont icon-shipin',
                isShow: true
            },
            {
                name: 'shortVideoWebSiteTool',
                icon: 'iconfont icon-douyin',
                isShow: true
            }
        ]
    },
    //小游戏
    {
        name: 'game',
        category: 'game',
        icon: 'iconfont icon-youxi',
        isShow: true,
        children: [
            {
                name: 'twoZeroFourEightGame',
                icon: 'iconfont icon-youxi',
                isShow: false
            },
            {
                name: 'twoZeroFourEightGame1',
                icon: 'iconfont icon-youxi',
                isShow: true
            },
            {
                name: 'tetrisGame',
                icon: 'iconfont icon-youxi',
                isShow: true
            },
        ]
    }
]
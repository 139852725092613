export default {
    common: {
        appName:"pg Tool",
        appKeyword: "pg tools, utility tools, programmer tools, online gadgets, online text tools, online picture tools, online calculation tools, online network tools, online life tools, online mini games",
        setting:'Setting',
        menu:'Menu',
        notFoundMsg: 'Sorry, page not found',
        jumpAfterSeconds: 's later, jump automatically...',

        button: {
            convert: "convert",
            format:'format',
            compress: 'compress',
            empty: "empty",
            copy: "copy",
            paste: "paste",
            upload: "upload",
            download: "download",
            fullscreen: 'fullscreen',
            test: "test",
            generate: "generate",
            compare: "compare",
            edit: "edit",
            textEditor: "text editor",
            encode: "encode",
            decode: "decode",
            encrypt: "encrypt",
            decrypt: "decrypt",
            operate: "operate",
            statistics: "statistics",
            locate: "locate",
            query: "query",
            translate: 'translate',
            search: 'search',
            keyword: ' keyword',
            open: 'open',
            exit: 'exit',
            escape: 'escape',
            unescape: 'unescape',
        },
        message: {
            searchForTool: 'Search For Tool',
            textContent: "text content",
            base64Content: "base64 content",
            copySuccess: 'copy success',
            copyFail: 'copy fail',
            copyFailEmptyText: 'copy fail, text is empty',
            pasteSuccess: 'paste success',
            pasteFail: 'paste fail',
            convertSuccess: 'convert success',
            convertFail: 'convert fail',
            querySuccess: 'query success',
            queryFail: 'query fail',
            encryptSuccess: 'encrypt success',
            encryptFail: 'encrypt fail',
            decryptSuccess: 'decrypt success',
            decryptFail: 'decrypt fail',
            encodeSuccess: 'encode success',
            encodeFail: 'encode fail',
            decodeSuccess: 'decode success',
            decodeFail: 'decode fail',
            pleaseConvert: 'please convert first',
            uploadSuccess: 'upload success',
            uploadFail: 'upload fail',
            formatSuccess: "format success",
            formatFail: "format fail",
            locateSuccess: 'locate success',
            locateFail: 'locate fail',
            translateSuccess: 'translate success',
            translateFail: 'translate fail',
            searchSuccess: 'search success',
            searchFail: 'search fail',
            requestSuccess: 'request success',
            requestFail: 'request fail',
            escapeSuccess: 'escape success',
            escapeFail: 'escape fail',
            unescapeSuccess: 'unescape success',
            unescapeFail: 'unescape fail',
            openSuccess: 'open success',
            openFail: 'open fail',
            downloadSuccess: 'download success',
            downloadFail: 'download fail',
            uploadOneFile: 'please upload one file',
            uploadFileSizeExceedLimit: 'upload file size exceed limit',
            input: 'please input',
            inputText: 'please input text',
            loading: 'loading',
            select: 'select',
            chooseType: 'please select type',
            wrongTextFormat: 'wrong text format',
            beforeConvert: 'before convert',
            afterConvert: 'after convert',
            testFail: 'test fail',
            match: 'result match',
            notMatch: 'result not match',
            regexpType: "regexp type",
            choose: "please choose",
            regexp: "regular expression",
            testContent: "test content",
            randomText: "random text",
            generateFail: "generate fail",
            generateSuccess: "generate success",
            wrongTextLength: "wrong text length",
            inputError: 'input error',
            noImage: "no image",
            nonsupportType: 'nonsupport type',
            textCompare: "text compare",
            leftText: "left text",
            rightText: "right text",
            theme: "theme",
            language: "language",
            textEditor: "text editor",
            statistics: "statistics",
            pleaseInput: "please input: ",
            key: "key",
            priKey: "private key",
            pubKey: "public key",
            iv: "iv",
            pleaseDownload: "please download file",
            location: 'location info',
            weather: 'weather info',
            yourPosition: '★',
            translate: 'translation',
            keyword: 'keyword',
            notFound: 'not found',
            picture: 'picture',
            docReader: 'document reader',
            time: 'time',
            requestPath: 'request path',
            value: 'value',
            unitType: 'unit type',
            unit: 'unit',
            suchAs: 'such as',
            colorFormatError: 'color format error',
            colorRgb:  'rgb color',
            colorHex: 'hex color',
            colorAlpha: 'alpha',
            switch: 'switch'
        },
        tip: {
            uploadImgTip: 'limit 1 file, new file will cover the old file'
        },
        colorTableType: {
            all: 'common color',
            safe: 'safe color'
        },
        caseType: {
            uppercase: 'uppercase',
            lowercase: 'lowercase'
        },
        regexType: {
            default: "RegExp type",
            mobile: "mobile",
            idCard: "id card",
            email: "email",
            chinese: "chinese",
            url: "url",
            httpUrl: "http ip address",
            ip4: "ip4 address",
            ip6: "ip6 address",
            money: "money",
            postCode: "postcode",
            mac: "mac",
            hex: "hex",
            time: "time",
            creditCode: "credit code",
            chCarCode: "plate number",
            general: "letter, number and underline",
            chGeneral: "letter, number, underline and chinese",
            number: "number",
            word: "letter",
        },
        randomType: {
            digit: "digit",
            smallChar: "lowercase",
            bigChar: "capital",
            special: "special",
            timestamp: "timestamp",
            datetimeString1: "datetimeString1",
            datetimeString2: "datetimeString2",
            uuid: "uuid",
            snowflakeId: "snowflakeId",
        },
        convertType: {
            jsonToXml: 'json to xml',
            xmlToJson: 'xml to json',
            lowerToUpper: 'lowercase to uppercase',
            upperToLower: 'uppercase to lowercase',
            jianToFan: 'simplified to traditional',
            fanToJian: 'traditional to simplified',
            cnToTw: 'simplified to traditional taiwanese',
            twToCn: 'traditional taiwanese to simplified',
            cnToTwp: 'simplified to traditional taiwanese(word)',
            twpToCn: 'traditional taiwanese(word) to simplified',
            cnToHk: 'simplified to traditional Hong Kong',
            hkToCn: 'traditional Hong Kong to simplified',
            cnToJp: 'simplified to kanji',
            jpToCn: 'kanji to simplified',
            numToCh: 'number to chinese',
            chToPinyin: 'chinese to pinyin',
        },
        codeDiffFormatType: {
            side: 'by side',
            line: 'by line'
        },
        showNoChangeType: {
            show: 'show diff',
            hide: 'hide diff'
        },
        formatCompressType: {
            format: "format",
            compress: 'compress'
        },
        picFilterType: {
            default: "no filter",
            relief1: "relief1",
            relief2: "relief2",
            sculpture: "sculpture",
            mirror: "mirror reverse",
            upsideDown: "upside down",
            negative:"negative",
            oldPhoto:"old photo",
            particle1:"particle1",
            particle2:"particle2",
            mosaic:"mosaic",
            mote:"mote",
            blackAndWhite:"blackAndWhite",
            gray1: "gray1",
            gray2: "gray2",
            gauss1:"gauss blur1",
            gauss2:"gauss blur2",
            gauss3:"gauss blur3",
            light:"light",
            dark:"dark",
            opacity:"opacity",
            rChannel:"red mixing",
            gChannel:"green mixing",
            bChannel:"blue mixing",
            rgChannel:"yellow mixing",
            rbChannel:"purple mixing",
            gbChannel:"cyan mixing",
            rgbChannel:"custom mixing",
            rMask:"red mask",
            gMask:"green mask",
            bMask:"blue mask",
            rgMask:"yellow mask",
            rbMask:"purple mask",
            gbMask:"cyan mask",
        },
        picClipType: {
            startClip: "start crop",
            stopClip: "stop crop",
            clearClip: "clear crop",
            bigger: "bigger",
            smaller: "smaller",
            rotateLeft: "rotate left",
            rotateRight: "rotate right",
            revert: "revert",
        },
        textStatistics: {
            total: 'total character',
            number: 'numeric character',
            letter: 'letter',
            upper: 'uppercase letter',
            lower: 'lowercase letter',
            ch: 'chinese letter',
            special: 'special character',
            whitespace: 'whitespace character',
            space: 'space character',
            tab: 'tab character',
            lf: 'CRLF character',
            size: 'text size',
            line: 'text line',
        },
        game: {
            start: "START",
            finish: "FINISH",
            lose: "you lose",
            over: "game over",
            pause: 'pause',
            resume: 'resume',
            pauseGame: 'game pause',
            resumeGame: 'game resume',
            score: "score",
            time: "time",
            costTime: "cost time"
        },
        time: {
            second: 's',
            minute: 'm',
            hour: 'h',
            day: 'd'
        },
        date: {
            Y: ':',
            M: ':',
            D: ' ',
            h: 'h',
            m: 'm',
            s: 's',
        },
        searchEngineType: {
            common: 'Integration',
            picture: 'Picture',
            video: 'Video',
            music: 'Music',
            news: 'News',
            blogs: 'Blogs',
            software: 'Software',
            shop: 'Shopping',
            knowledge: 'Knowledge',
            encyclopedia: 'Encyclopedia',
            document: 'Document',
            map: 'Map',
            doctor: 'Doctor',
        },
        api: {
            baidu: 'Baidu',
            tencent: 'Tencent',
            amap: 'Amap',

            weibo: 'Weibo',
            weixin: 'Weixin',
            zhihu: 'Zhihu',

            iciba: 'Iciba',
            youdao: 'Youdao',

            so: '360',
            sogou: 'Sogou',
            bing: 'Bing',
            google: "Google",
            chinaso: "ChinaSo",

            wenxinyiyan: 'yiyan',
            xunfeixinghuo: 'xinghuo',

            kugouMusic: 'kugou music',
            kuwoMusic: 'kuwo music',
            wangyiyunMusic: 'wangyiyun music',
            tencentMusic: 'QQ Music',
            qianqianMusic: '91 Music',
            jiukuMusic: '9ku Music',
            yitingMusic: '1ting Music',

            zjwaterTyphoon: 'zjwater Typhoon',
            weatherTyphoon: 'weather Typhoon',
            istrongcloudTyphoon: 'istrongcloud Typhoon',
            ceicEarthQuake: 'ceic EarthQuake',

            tencentNews: "Tencent News",
            toutiao: "Toutiao",
            wangyiNews: "163 News",
            sinaNews: "Sina  News",
            sohuNews: "Sohu News",
            baiduNews: "Baidu News",
            cctvNews: "Cctv News",
            chinaNews: "China News",
            ifengNews: "Ifeng News",
            huanqiuNews: "Huanqiu News",
            xinhuaNews: "Xinhua News",

            bilibili: "bilibili",
            baiduVideo: "Baidu Video",
            sohuVideo: "Sohu Video",
            mgtv: "Mgtv",
            baofeng: "Baofeng",
            pptv: "Pptv",
            hao123Video: "Hao123 Video",
            youku: "Youku",
            aiqiyi: "Aiqiyi",
            xigua: "Xigua",
            tudou: "Tudou",
            iqiyi: "Iqiyi",
            tencentVideo: "Tencent Video",

            douyin: "douyin",
            kuaishou: "Kuaishou",
            haokan: "Haokan",
        },
        location: {
            address: 'address',
            country: 'country',
            province: 'province',
            city: 'city',
            district: 'district',
            street: 'street',
            streetCode: 'street code',
            town: 'town',
            townCode: 'town code',
            addressCode: 'address code',
            longitude: 'longitude',
            latitude: 'latitude',
            nation: 'nation',
            nationCode: 'nation code',
            cityPosition: 'City coordinates (bottom left and top right)',
            current: 'current',
            coordinate: 'coordinate'
        },
        weatherType: {
            weather: "weather",
            typhoon: "typhoon",
            earthquake: "earthquake",
        },
        weather: {
            todayWeather: 'today weather',
            text: 'weather describe',
            temp: 'temperature',
            feelTemp: 'feel temperature',
            rh: 'relative humidity',
            windClass: 'wind class',
            windDesc: 'wind describe',
            updateTime: 'update time',
            week: 'week',
            textDay: 'daytime weather describe',
            textNight: 'night weather describe',
            tempHigh: 'maximum temperature',
            tempLow: 'minimum temperature',
            tempDay: 'daytime temperature',
            tempNight: 'night temperature',
            windClassDay: 'daytime wind class',
            windDescDay: 'daytime wind describe',
            windClassNight: 'night wind class',
            windDescNight: 'night wind describe',
        },
        timeFormat: {
            auto: 'auto detect',
            timestamp: 'Timestamp',
            timeInterval: 'Time Interval',
            ymdhms1: 'yyyyMMddHHmmss',
            ymdhms2: 'yyyy-MM-dd HH:mm:ss',
            ymdhms3: 'yyyy/MM/dd HH:mm:ss',
            ymdhmsS1: 'yyyyMMddHHmmssSSS',
            ymdhmsS2: 'yyyy-MM-dd HH:mm:ss:SSS',
            ymdhmsS3: 'yyyy/MM/dd HH:mm:ss:SSS',
            ymd1: 'yyyyMMdd',
            ymd2: 'yyyy-MM-dd',
            ymd3: 'yyyy/MM/dd',
            pat: {
                ymdhms1: 'yyyyMMddHHmmss',
                ymdhms2: 'yyyy-MM-dd HH:mm:ss',
                ymdhms3: 'yyyy/MM/dd HH:mm:ss',
                ymdhmsS1: 'yyyyMMddHHmmssSSS',
                ymdhmsS2: 'yyyy-MM-dd HH:mm:ss:SSS',
                ymdhmsS3: 'yyyy/MM/dd HH:mm:ss:SSS',
                ymd1: 'yyyyMMdd',
                ymd2: 'yyyy-MM-dd',
                ymd3: 'yyyy/MM/dd',
            }
        },
        unitType: {
            length: {
                name: 'length',
                m: 'meter (m)',
                km: 'km (km)',
                dm: 'decimeter (dm)',
                cm: 'centimeter (cm)',
                mm: 'mm (mm)',
                um: 'micron (um)',
                nm: 'nanometer (nm)',
                pm: 'Pimi (pm)',
                ly: 'Light Year (ly)',
                au: 'Astronomical Unit (AU)',
                in: 'inch (in)',
                ft: 'feet (ft)',
                yd: 'Code (yd)',
                mi: 'mile (mi)',
                nmi: 'Nautical mile (nmi)',
                fm: 'fathom (fm)',
                fur: 'fur (fur)',
                mil: 'mil (mil)',
                cli: '里',
                czhang: '丈',
                cchi: '尺',
                ccun: '寸',
                cfen: '分',
                cli1: '厘',
                chao: '毫',
            },
            area: {
                name: 'area',
                m2: 'square meter (m²)',
                km2: 'square kilometres (km²)',
                ha: 'hectare (ha)',
                are: 'acre (are)',
                dm2: 'square decimeter (dm²)',
                cm2: 'square centimeter (cm²)',
                mm2: 'square millimetres (mm²)',
                acre: 'uk.acre ',
                sqmi: 'Square mile (sq.mi)',
                sqyd: 'Square code (sq.yd)',
                sqft: 'square feet (sq.ft)',
                sqin: 'square inch (sq.in)',
                sqrd: 'square rd (sq.rd)',
                cqing: '顷',
                cmu: '亩',
                cfen: '分',
                cchi: '平方尺',
                ccun: '平方寸',
            },
            volume: {
                name: 'volume',
                m3: 'cubic meter (m³)',
                km3: 'cubic kilometers (km³)',
                dm3: 'cubic decimeter (dm³)',
                cm3: 'cubic centimeter (cm³)',
                mm3: 'cubic millimeter (mm³)',
                l: 'liter (l)',
                dl: 'deciliter (dl)',
                ml: 'milliliter (ml)',
                cl: 'centiliter (cl)',
                ul: 'microliter (ul)',
                hl: 'public Stone (hl)',
                cuft: 'cubic feet (cu ft)',
                cuin: 'cubic inch (cu in)',
                cuyd: 'Cubic yards (cu yd)',
                min: 'acre in ',
                ukgal: 'ukgal ',
                usgal: 'usgal ',
                ukoz: 'Imperial Liquid Ounce (oz)',
                usoz: 'American Liquid Ounce (oz)',
            },
            weight: {
                name: 'weight',
                kg: 'kg (kg)',
                g: 'gram (g)',
                mg: 'milligram (mg)',
                ug: 'Microgram (μg)',
                t: 'ton (t)',
                q: 'quintal (q)',
                lb: 'pound (lb)',
                oz: 'oz ',
                ct: 'carat (ct)',
                gr: 'Gring (gr)',
                lt: 'Long ton (lt)',
                st: 'Short ton (st)',
                ukhw: 'hundredweight ',
                ushw: 'Metricam ',
                dr: 'LAN (dr)',
                cdan: '担',
                cjin: '斤',
                cliang: '两',
                cqian: '钱',
                cfen: '分',
            },
            temperature: {
                name: 'temperature',
                C: '摄氏度(℃)',
                K: '开氏度(K)',
                F: '华氏度(℉)',
                R: '兰氏度(°R)',
                Re: '列氏度(°Re)',
            },
            pressure: {
                name: 'pressure',
                pa: 'PASCAL (Pa)',
                hpa: 'hpa ',
                kpa: 'kpa ',
                mpa: 'MPa ',
                atm: 'Standard Atmosphere (atm)',
                mmHg: 'millimetre of mercury (mmHg)',
                inHg: 'inch of mercury (in Hg)',
                bar: 'bar ',
                mbar: 'mbar ',
                psf: 'Pound force per square foot (psf)',
                psi: 'Pound force per square inch (psi)',
                mmAq: 'millimetre water column ',
                kgfcm2: 'kg force/cm2 (kgf/cm²)',
                kgfm2: 'kg force/square meter (kgf/ square meter)',
            },
            power: {
                name: 'power',
                w: 'watt (W)',
                kw: 'kilowatt (kW)',
                hp: 'Imperial horsepower (hp)',
                ps: 'metric horsepower (ps)',
                kgms: 'kg·m/s (kg·m/s)',
                kcals: 'kcal/second (kcal/s)',
                btus: 'British thermal unit per second (Btu/s)',
                ftlbs: 'feet · pounds per second (ft·lb/s)',
                js: 'Joules per second (J/s)',
                nms: 'Newton ·m/s (N·m/s)',
            },
            energy: {
                name: 'energy',
                j: 'Joule (J)',
                kj: 'kilojoules (kJ)',
                kgm: 'kg·m (kg·m)',
                psh: 'Metric horsepower · hour (ps·h)',
                hph: 'Imperial horsepower · hour (hp·h)',
                kwh: 'Kilowatt · hour (kW·h)',
                cal: 'Card (cal)',
                kcal: 'kcal ',
                btu: 'British Thermal Unit (btu)',
                ftlb: 'ft·lb ',
            },
            density: {
                name: 'density',
                kgm3: 'kg/cubic meter (kg/m³)',
                kgcm3: 'kg/cubic centimeter (kg/cm³)',
                kgdm3: 'kg/cubic decimeter (kg/dm³)',
                gcm3: 'g/cubic centimeter (g/cm³)',
                gdm3: 'g/cubic decimeter (g/dm³)',
                gm3: 'g/cubic meter (g/m³)',
            },
            force: {
                name: 'force',
                n: 'cow (N)',
                kn: 'kN ',
                kgf: 'Kilogram Force (kgf)',
                gf: 'gf ',
                tf: 'Metric ton Force (tf)',
                ip: 'Pound Force (ip)',
                kip: 'Thousand Pound Force (kip)',
                dyn: 'dyn ',
            },
            time: {
                name: 'time',
                s: 'second (s)',
                yr: 'yr ',
                week: 'week ',
                d: 'day ',
                h: 'hour (h)',
                min: 'min ',
                ms: 'milliseconds (ms)',
                us: 'microsecond (μs)',
                ns: 'nanosecond (ns)',
            },
            speed: {
                name: 'speed',
                ms: 'm/s (m/s)',
                kms: 'km/s (km/s)',
                kmh: 'km/h (km/h)',
                c: 'Speed of light (c)',
                mach: 'mach ',
                mileh: 'mile/h ',
                ins: 'inches per second (in/s)',
            },
            dataSize: {
                name: 'store',
                b: 'byte (b)',
                bit:' bit ',
                kb: 'kilobyte (KB)',
                mb: 'Megabyte (MB)',
                gb: 'Gigabyte (GB)',
                tb: 'terabyte (TB)',
                pb: 'petabyte (PB)',
                eb: 'exabyte (EB)',
            },
            angle: {
                name: 'angle',
                d: 'Angle (°)',
                circle: 'circle ',
                rg: 'right angle ',
                gon: 'percentage (gon)',
                minute: 'min ',
                second: 'sec ',
                rad: 'radian (rad)',
                mrad: 'milliradian (mrad)',
            },
            rmb: {
                name: 'rmb',
                yuan: 'yuan',
                jiao: 'jiao',
                fen: 'fen',
            },
        }
    },
    setting: {
        darkMode: 'Dark Mode',
        enMode: "Language Mode",
        leftRightPattern: "Left Right Pattern",
        topMenuPattern: "Top Menu Pattern",
        secondMenuPattern: "Second Menu Pattern",
        themeColor: "Theme Color"
    },
    menu: {
        textTool: "Text Tool",
        textEditorTool: "Text Editor Tool",
        docReaderTool: "Document Reader Tool",
        textFormatTool: "Text Format Tool",
        jsonEditorTool: "Json Editor Tool",
        timeFormatTool: "Time Format Tool",
        textConversionTool: "Text Conversion Tool",
        textRegexpTool: "Text RegExp Tool",
        textRandomTool: "Text Random Tool",
        textCompareTool: "Text Compare Tool",
        textEncodeTool: "Text Encode Tool",
        textEncryptTool: "Text Encrypt Tool",
        textStatisticsTool: "Text Statistics Tool",
        textTranslateTool: "Text Translate Tool",
        textEscapeTool: "Text escape tool",
        cronEditorTool: 'Crontab Tool',

        pictureTool: "Picture Tool",
        picBase64Tool: "Picture Base64 Tool",
        picFormatTool: "Picture Format Tool",
        picFilterTool: "Picture Filter Tool",
        picClipTool: "Picture Crop Tool",
        picSearchTool: "Picture Search Tool",
        qrCodeTool: 'QrCode Tool',
        colorTool: 'Color tool',

        calculatorTool: "Calculator Tool",
        unitConverterTool: "Unit Converter Tool",

        lifeTool: "Life Tool",
        locationTool: "Location Tool",
        weatherTool: "Weather Tool",

        websiteTool: "Website Tool",
        searchEngineTool: 'Search Engine Website',
        aiChartTool: 'Ai Chart Website',
        musicWebsiteTool: 'Music Website',
        newsWebsiteTool: 'News Website',
        videoWebsiteTool: 'Video Website',
        shortVideoWebSiteTool: 'Short Video Website',

        game: "game",
        twoZeroFourEightGame: "2048",
        twoZeroFourEightGame1: "2048",
        tetrisGame: "tetris",
    },
    menuDesc: {
        textTool: "Text Tool",
        textEditorTool: "Javascript/HTML/CSS/Java/python/XML/json/.. dozens of format text editor, based on the ace",
        docReaderTool: "a word/excel/pdf/ppt Document Reader Tool",
        textFormatTool: "a json, xml text format tool",
        jsonEditorTool: "a json editor tool",
        timeFormatTool: "a timestamp/time string format tool",
        textConversionTool: "a json、xml text conversion tool",
        textRegexpTool: "a regular expression verification tool",
        textRandomTool: "a random number, random text generation tool",
        textCompareTool: "a text content compare tool",
        textEncodeTool: "a text encode decode tool",
        textEncryptTool: "a text encrypt decrypt tool",
        textStatisticsTool: "a text character statistics tool",
        textTranslateTool: "a text translate tool",
        textEscapeTool: "a text escape tool",
        cronEditorTool: 'a crontab generate/convert tool',

        pictureTool: "Picture Tool",
        picBase64Tool: "a picture, base64 string conversion tool",
        picFormatTool: "a jpg/png/webp/.. picture format tool",
        picFilterTool: "a photo filter tool",
        picClipTool: "a picture crop/bigger/smaller/rotate tool",
        picSearchTool: "a picture search tool",
        qrCodeTool: 'a qrcode generate/parse tool',
        colorTool: 'a color tool',

        calculatorTool: "Calculator Tool",
        unitConverterTool: "a tool for length/area/volume/mass/temperature/time/data/speed and other unit conversion",

        lifeTool: "Life Tool",
        locationTool: "a tool for obtain the ip/latitude/province/city",
        weatherTool: "a tool for obtain the present weather and forecast future weather",

        websiteTool: "Website Tool",
        searchEngineTool: 'search engine websites',
        aiChartTool: 'artificial intelligence chat websites',
        musicWebsiteTool: 'music websites',
        newsWebsiteTool: 'news websites',
        videoWebsiteTool: 'video websites',
        shortVideoWebSiteTool: 'short video websites',

        game: "small game",
        twoZeroFourEightGame: "2048 small game",
        twoZeroFourEightGame1: "2048 small game",
        tetrisGame: "tetris small game",
    }
};
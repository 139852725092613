//import { createApp } from 'vue'
//import App from './App.vue'
//createApp(App).mount('#app')

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n/index'
import ElementPlus from 'element-plus'     //需要引入的部分1
import 'element-plus/dist/index.css'       //需要引入的部分2
import 'element-plus/theme-chalk/dark/css-vars.css' //暗黑模式
import "@/assets/iconfont/iconfont.css"; //iconfont图标
import "@/assets/iconfont/iconfont_custom.css"; //iconfont图标自定义设置
import './style/common.scss'
import './style/el-customer.css'
import CodeDiff from 'v-code-diff'

//注册图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//需要引入的部分3
const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(ElementPlus, { size: 'default', zIndex: 3000 })
    .use(CodeDiff)
    .mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
import { createRouter, createWebHashHistory } from "vue-router"
// import store from '@/store/index';

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const routes = [
    /*{
        path: "/:pathMatch(.*)",
        redirect: "/404"
    },*/
    {
        path: '/',
        name: 'index',
        component: () => import('../components/IndexPage.vue'),
        children: [
            //未找到
            {
                path: "/404",
                name: "NotFound",
                component: () => import ('../components/NotFound.vue')
            },
            //首页
            {
                path: "/home",
                name: "HomePage",
                component: () => import ('../components/HomePage.vue')
            },
            {
                path: "/secondHome",
                name: "SecondHomePage",
                component: () => import ('../components/SecondHomePage.vue')
            },
            //文本工具
            {
                path: "/menu/text/textEditorTool",
                name: "textEditorTool",
                component: () => import('../components/menu/text/TextEditorTool.vue')
            },
            {
                path: "/menu/text/docReaderTool",
                name: "docReaderTool",
                component: () => import('../components/menu/text/DocReaderTool.vue')
            },
            {
                path: "/menu/text/textFormatTool",
                name: "textFormatTool",
                component: () => import('../components/menu/text/TextFormatTool.vue')
            },
            {
                path: "/menu/text/jsonEditorTool",
                name: "jsonEditorTool",
                component: () => import('../components/menu/text/JsonEditorTool.vue')
            },
            {
                path: "/menu/text/timeFormatTool",
                name: "timeFormatTool",
                component: () => import('../components/menu/text/TimeFormatTool.vue')
            },
            {
                path: "/menu/text/textConversionTool",
                name: "textConversionTool",
                component: () => import('../components/menu/text/TextConversionTool.vue')
            },
            {
                path: "/menu/text/textRegexpTool",
                name: "textRegexpTool",
                component: () => import('../components/menu/text/TextRegexpTool.vue')
            },
            {
                path: "/menu/text/textRandomTool",
                name: "textRandomTool",
                component: () => import('../components/menu/text/TextRandomTool.vue')
            },
            {
                path: "/menu/text/textCompareTool",
                name: "textCompareTool",
                component: () => import('../components/menu/text/TextCompareTool.vue')
            },
            {
                path: "/menu/text/textEncodeTool",
                name: "textEncodeTool",
                component: () => import('../components/menu/text/TextEncodeTool.vue')
            },
            {
                path: "/menu/text/textEncryptTool",
                name: "textEncryptTool",
                component: () => import('../components/menu/text/TextEncryptTool.vue')
            },
            {
                path: "/menu/text/textStatisticsTool",
                name: "textStatisticsTool",
                component: () => import('../components/menu/text/TextStatisticsTool.vue')
            },
            {
                path: "/menu/text/textTranslateTool",
                name: "textTranslateTool",
                component: () => import('../components/menu/text/TextTranslateTool.vue')
            },
            {
                path: "/menu/text/textEscapeTool",
                name: "textEscapeTool",
                component: () => import('../components/menu/text/TextEscapeTool.vue')
            },
            {
                path: "/menu/text/cronEditorTool",
                name: "cronEditorTool",
                component: () => import('../components/menu/text/CronEditorTool.vue')
            },
            //图片工具
            {
                path: "/menu/picture/picBase64Tool",
                name: "picBase64Tool",
                component: () => import('../components/menu/picture/PicBase64Tool.vue')
            },
            {
                path: "/menu/picture/picFormatTool",
                name: "picFormatTool",
                component: () => import('../components/menu/picture/PicFormatTool.vue')
            },
            {
                path: "/menu/picture/picFilterTool",
                name: "picFilterTool",
                component: () => import('../components/menu/picture/PicFilterTool.vue')
            },
            {
                path: "/menu/picture/picClipTool",
                name: "picClipTool",
                component: () => import('../components/menu/picture/PicClipTool.vue')
            },
            {
                path: "/menu/picture/picSearchTool",
                name: "picSearchTool",
                component: () => import('../components/menu/picture/PicSearchTool.vue')
            },
            {
                path: "/menu/picture/qrCodeTool",
                name: "qrCodeTool",
                component: () => import('../components/menu/picture/QrCodeTool.vue')
            },
            {
                path: "/menu/picture/colorTool",
                name: "colorTool",
                component: () => import('../components/menu/picture/ColorTool.vue')
            },
            //计算工具
            {
                path: "/menu/calculator/unitConverterTool",
                name: "unitConverterTool",
                component: () => import('../components/menu/calculator/UnitConverterTool.vue')
            },
            //生活工具
            {
                path: "/menu/life/locationTool",
                name: "locationTool",
                component: () => import('../components/menu/life/LocationTool.vue')
            },
            {
                path: "/menu/life/weatherTool",
                name: "weatherTool",
                component: () => import('../components/menu/life/WeatherTool.vue')
            },
            //网址工具
            {
                path: "/menu/website/searchEngineTool",
                name: "searchEngineTool",
                component: () => import('../components/menu/website/SearchEngineTool.vue')
            },
            {
                path: "/menu/website/aiChartTool",
                name: "aiChartTool",
                component: () => import('../components/menu/website/AiChartTool.vue')
            },
            {
                path: "/menu/website/newsWebsiteTool",
                name: "newsWebsiteTool",
                component: () => import('../components/menu/website/NewsWebsiteTool.vue')
            },
            {
                path: "/menu/website/musicWebsiteTool",
                name: "musicWebsiteTool",
                component: () => import('../components/menu/website/MusicWebsiteTool.vue')
            },
            {
                path: "/menu/website/videoWebsiteTool",
                name: "videoWebsiteTool",
                component: () => import('../components/menu/website/VideoWebsiteTool.vue')
            },
            {
                path: "/menu/website/shortVideoWebSiteTool",
                name: "shortVideoWebSiteTool",
                component: () => import('../components/menu/website/ShortVideoWebSiteTool.vue')
            },
            //小游戏
            {
                path: "/menu/game/twoZeroFourEightGame",
                name: "twoZeroFourEightGame",
                component: () => import('../components/menu/game/TwoZeroFourEightGame.vue')
            },
            {
                path: "/menu/game/twoZeroFourEightGame1",
                name: "twoZeroFourEightGame1",
                component: () => import('../components/menu/game/TwoZeroFourEightGame1.vue')
            },
            {
                path: "/menu/game/tetrisGame",
                name: "tetrisGame",
                component: () => import('../components/menu/game/TetrisGame.vue')
            },
        ]
    }
]
export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

/*let newRoutes;
router.beforeEach((to, from, next) => {
    if (!newRoutes) {
        newRoutes = store.getters.menuToRouter
        newRoutes.forEach((val) => {
            router.addRoute(val)
        })
        next({
            ...to,
            replace: true
        })
    }

})*/

NProgress.configure({
    easing: 'ease', // 动画方式
    speed: 500, // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比
})


router.beforeEach((to, from, next) => {
    //进度条开始
    NProgress.start();
    if(to.path.indexOf('notJump') !== -1){
        return false;
    }else{
        let hasContain = false;
        for (let i = 0; i < routes.length; i++) {
            let route = routes[i];
            if (route.path === to.path) {
                hasContain = true;
                break;
            }
            if (route.children  && route.children.length > 0) {
                for (let j = 0; j < route.children.length; j++) {
                    let childRoute = route.children[j];
                    if (childRoute.path === to.path) {
                        hasContain = true;
                        break;
                    }
                }
            }
        }
        if (hasContain) {
            next();
        } else {
            next({path:'/404'})
        }
    }
})

//后置路由
router.afterEach(() => {
    //关闭掉进度条
    NProgress.done();
});

export default router
import {createStore} from "vuex";
import {pgMenuList} from '../js/sys/pgMenuList';
import Vue from 'vue';
//创建存储对象
export default createStore({
    // 需要存储的值都放在这里面
    state() {
        return {
            serverAddress: 'https://pgtool.top:8081',
            serverStaticAddress: 'http://pgtool.top:8099',
            count: 0,    // 在视图中通过$store.state.count来获取
            setting: {
                darkPattern: localStorage.getItem("setting.darkPattern") ? localStorage.getItem("setting.darkPattern") === 'true' : false,
                leftRightPattern: localStorage.getItem("setting.leftRightPattern") ? localStorage.getItem("setting.leftRightPattern") === 'true' : false,
                topMenuPattern: localStorage.getItem("setting.topMenuPattern") ? localStorage.getItem("setting.topMenuPattern") === 'true' : false,
                secondMenuPattern: localStorage.getItem("setting.secondMenuPattern") ? localStorage.getItem("setting.secondMenuPattern") === 'true' : true,
                themeColor: localStorage.getItem("setting.themeColor") ? localStorage.getItem("setting.themeColor") : "blue",
            },
            homeMenuList: localStorage.getItem("homeMenuList") ? JSON.parse(localStorage.getItem("homeMenuList")) : [],
            secondHomeMenuList: [],
            secondHomeMenuCategory: '',
            currentComponentName: '',
            menuList: []
        };
    },
    // 在其他视图中通过 $store.commit('setState', 10) 使用，用于修改store存的值
    mutations: {
        setCount(state, count) {    // 只能接受两个参数，用于修改store存的值
            state.count = count;
        },
        setMenuList(state, menuList) {
            state.menuList = menuList;
        },
        setDarkPattern(state, darkPattern) {
            state.setting.darkPattern = darkPattern;
            localStorage.setItem("setting.darkPattern", darkPattern);
        },
        setLeftRightPattern(state, leftRightPattern) {
            state.setting.leftRightPattern = leftRightPattern;
            localStorage.setItem("setting.leftRightPattern", leftRightPattern);
        },
        setTopMenuPattern(state, topMenuPattern) {
            state.setting.topMenuPattern = topMenuPattern;
            localStorage.setItem("setting.topMenuPattern", topMenuPattern);
        },
        setSecondMenuPattern(state, secondMenuPattern) {
            state.setting.secondMenuPattern = secondMenuPattern;
            localStorage.setItem("setting.secondMenuPattern", secondMenuPattern);
        },
        setThemeColor(state, themeColor) {
            state.setting.themeColor = themeColor;
            localStorage.setItem("setting.themeColor", themeColor);
        },
        setSecondHomeMenuList(state, secondHomeMenuList) {
            state.secondHomeMenuList = secondHomeMenuList;
        },
        setSecondHomeMenuCategory(state, category) {
            state.secondHomeMenuCategory = category;
        },
        setCurrentComponentName(state, name) {
            state.currentComponentName = name;
        },
        addHomeMenu(state, menuPath) {
            let menuIcon = '';
            let menuName = menuPath.substring(menuPath.lastIndexOf("/") + 1, menuPath.length);
            pgMenuList.forEach(menu => {
                if (menuIcon != '') {
                    return;
                }
                menu.children.forEach(subMenu => {
                    if (menuIcon != '') {
                        return;
                    }
                    if (subMenu.name === menuName) {
                        menuIcon = subMenu.icon;
                        return;
                    }
                })
            })
            let newHomeMenuList = [];
            newHomeMenuList.push({
                name: menuName,
                path: menuPath,
                icon: menuIcon
            })
            for (let i = 0; i < state.homeMenuList.length; i++) {
                if (state.homeMenuList[i].name === menuName) {
                    continue;
                } else {
                    newHomeMenuList.push(state.homeMenuList[i]);
                }
            }
            if (newHomeMenuList.length > 8) {
                newHomeMenuList = newHomeMenuList.slice(0, 8);
            }
            state.homeMenuList = newHomeMenuList;
            localStorage.setItem("homeMenuList", JSON.stringify([]));
            localStorage.setItem("homeMenuList", JSON.stringify(newHomeMenuList));
        }
    },
    // 相当于组件的计算属性 通过 $store.getters.doubleCount 获取计算后的值
    getters: {
        doubleCount(state) {
            return state.count * 2;
        },
        menuToRouter(state) {
            let children = []
            pgMenuList.forEach(menu => {
                menu.children.forEach(subMenu => {
                    let menuRoute = {
                        path: "/menu/" + menu.category + "/" + subMenu.name,
                        name: subMenu.name,
                        component: () => import('../components/menu/' + menu.category + '/' + subMenu.name.substring(0, 1).toUpperCase() + subMenu.name.substring(1, subMenu.name.length) + '.vue')
                    }
                    children.push(menuRoute)
                })
            })
            let router = [
                {
                    path: '/',
                    name: 'index',
                    component: () => import('../components/IndexPage.vue'),
                    children
                }
            ]
            return router;
        },
        menuToSearchOption(state) {
            let options = []
            pgMenuList.forEach(menu => {
                if (menu.isShow) {
                    menu.children.forEach(subMenu => {
                        if (subMenu.isShow) {
                            let option = {
                                value: "/menu/" + menu.category + "/" + subMenu.name,
                                label: subMenu.name
                            }
                            options.push(option)
                        }

                    })
                }
            })
            return options;
        },
        secondMenuToSearchOption(state) {
            let options = []
            state.secondHomeMenuList.forEach(menu => {
                let option = {
                    value: menu.path,
                    label: menu.name
                }
                options.push(option)
            })
            return options;
        },
        findComponentByCategory(state) {
            let componentName = '';
            pgMenuList.forEach(menu => {
                if (menu.category === state.secondHomeMenuCategory) {
                    componentName = menu.name;
                    return;
                }
            })
            return componentName;
        }
    },
    // 异步任务 不会改变state 通过 $store.dispath('doubleCount') 使用
    actions: {
        doubleCount(context) {
            context.commit("doubleCount");
        },
    },
    // store的下级store 方便大型项目复杂数据管理，这里面相当于可以在放置一个和外面这些一样的模块
    modules: {},
});